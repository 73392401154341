



























































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from 'vue-property-decorator'
import Table from '@/components/Table/index.vue'
import Pagination from '@/components/Pagination/index.vue'
import ImgView from '@/components/ImgView/index.vue'
import { DatePicker, Tooltip } from 'element-ui'
Vue.use(DatePicker)
Vue.use(Tooltip)

@Component({
  components: {
    Table,
    Pagination,
    ImgView
  }
})
export default class VisitorList extends Vue {
  $constants: any
  searchModel = {
    key: 'time',
    value: null
  }
  listLoading = false
  tableHeader = [
    {
      key: 'residentName',
      name: '业主名',
      width: '10%'
    },
    {
      key: 'content',
      name: '反馈内容',
      width: '30%',
      isSpecial: true
    },
    {
      key: 'photos',
      name: '反馈图',
      width: '43%',
      isSpecial: true
    },
    {
      key: 'feedbackTime',
      name: '反馈时间',
      width: '14%'
    }
  ]
  tableData: any = []
  layout = 'total, sizes, prev, pager, next, jumper'
  total = 0
  listQuery = {
    page: 1,
    limit: 10
  }
  imgUrl = ''
  disabledTip = false

  created() {
    this.getOpinionList()
  }

  mouseoverTip($event: any) {
    this.disabledTip = false
    if (
      $event.target.offsetWidth <
      ($event.target.parentNode as any).offsetWidth - 3
    ) {
      this.disabledTip = true
    }
  }
  mouseoutTip() {
    this.disabledTip = false
  }

  //查询
  searchClick() {
    this.listQuery.page = 1
    this.listQuery.limit = 15
    this.getOpinionList()
  }
  // 获取意见列表
  getOpinionList() {
    this.listLoading = true
    this.$api.property
      .getOpinionList({
        current: this.listQuery.page,
        size: this.listQuery.limit,
        feedbackTimeStart: (this.searchModel as any).value
          ? (this.searchModel as any).value[0]
          : '',
        feedbackTimeEnd: (this.searchModel as any).value
          ? (this.searchModel as any).value[1]
          : ''
      })
      .then((res: any) => {
        this.listLoading = false
        const data = res.data
        this.tableData = data.data
        this.tableData.map((item: any) => {
          item.show = false
        })
        this.total = data.total
      })
      .catch(() => {
        this.listLoading = false
      })
  }

  // 查看图片
  viewImg(url: string) {
    this.imgUrl = url
  }

  // 关闭图片查看
  closeImg() {
    this.imgUrl = ''
  }
}
