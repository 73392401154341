











import { Component, Prop, Vue } from 'vue-property-decorator'
@Component
export default class ImgView extends Vue {
  @Prop() private url!: string

  bgstyle = `background-image: url(${this.url})`

  close() {
    this.$emit('close')
  }
}
